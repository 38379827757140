<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">

      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>Show</label>
            <v-select v-model="perPage" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="perPageOptions"
              :clearable="false" class="per-page-selector d-inline-block mx-50" />
            <label>entries</label>
          </b-col>

          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-dropdown split dropleft>
                <template #button-content>
                  <b-button @click="fetchProjects" size="sm">
                    <span class="text-nowrap">Filter</span>
                  </b-button>
                </template>

                <div class="p-1 dropdown-form-container">
                  <b-dropdown-form class="flex column">

                    <b-form-group @submit.stop.prevent>
                      <div class="position-relative">
                        <b-form-input class="search-group-input" placeholder="Type to Search..." />
                        <b-icon icon="search" class="search-group-icon"></b-icon>
                      </div>
                    </b-form-group>


                    <div class="date-filters-container">
                      <b-form-group @submit.stop.prevent class="width-49perc">
                        <b-form-input v-model="creationDateQuery" type="date" class="color-inherit" />
                      </b-form-group>
                      <b-form-group @submit.stop.prevent class="width-49perc">
                        <b-form-input v-model="completionDateQuery" type="date" class="color-inherit" />
                      </b-form-group>
                    </div>


                    <b-form-group>
                      <b-form-select v-model="clientQuery" :options="clientsOptions" placeholder="Client" />
                    </b-form-group>

                    <b-form-group @submit.stop.prevent>
                      <b-form-select v-model="designerQuery" :options="professionalsOptions" placeholder="Professional" />
                    </b-form-group>

                    <b-form-group @submit.stop.prevent>
                      <b-form-select v-model="paymentQuery" :options="paymentOptions" placeholder="Payment Option" />
                    </b-form-group>

                  </b-dropdown-form>
                </div>

              </b-dropdown>


              <!-- <b-button variant="primary" :to="{ name: 'UsersCreate' }">
                <span class="text-nowrap">Add User</span>
              </b-button> -->
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table ref="projectsTable" class="position-relative text-nowrap" :items="statusProjects" responsive
        :fields="tableColumns" show-empty empty-text="No matching records found" :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc">

        <!-- Column: Phone -->
        <template #cell(projectId)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.projectNumber }}</span>
          </div>
        </template>

        <!-- Column: Role -->
        <template #cell(projectName)="data">
          <b-link class="font-weight-bold d-block text-nowrap"
            :to="{ name: 'ProjectDetails', params: { projectId: data.item.projectId } }">
            {{ data.item.projectName }}
          </b-link>
        </template>

        <template #cell(ownerFullName)="data">
          <b-link class="font-weight-bold d-block text-nowrap"
            :to="{ name: 'UsersEdit', params: { userId: data.item.ownerId } }">
            {{ data.item.ownerFullName }}
          </b-link>
        </template>

        <template #cell(professionalFUllName)="data">
          <div v-if="data.item.professionalFullName.trim()">
            <b-link class="font-weight-bold d-block text-nowrap"
              :to="{ name: 'FreelancersShow', params: { professionalId: data.item.professionalId } }">
              {{ data.item.professionalFullName }}
            </b-link>
          </div>
          <span v-else class="align-text-top">-</span>
        </template>

        <template #cell(country)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.country }}</span>
          </div>
        </template>

        <template #cell(projectPercentage)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.projectPercentage }}</span>
          </div>
        </template>

        <template #cell(amountInUSD)="data">
          <div class="text-nowrap">
            <span v-if="data.item.amountInUSD" class="align-text-top">{{ data.item.amountInUSD }}$</span>
            <span v-else class="align-text-top">-</span>
          </div>
        </template>

        <template #cell(bainkomPercentage)="data">
          <div class="text-nowrap">
            <span v-if="data.item.bainkomPercentage" class="align-text-top">{{ data.item.bainkomPercentage * 100}}%</span>
            <span v-else class="align-text-top">-</span>
          </div>
        </template>

        <template #cell(fullAmountInUSD)="data">
          <div class="text-nowrap">
            <span v-if="data.item.fullAmountInUSD" class="align-text-top">{{ data.item.fullAmountInUSD }}$</span>
            <span v-else class="align-text-top">-</span>
          </div>
        </template>

        <template #cell(paymentOption)="data">
          <div class="text-nowrap">
            <span v-if="data.item.paymentOption" class="align-text-top rounded" :class="paymentOptionClass(data.item.paymentOption)">{{  data.item.paymentOption }}</span>
            <span v-else class="align-text-top">-</span>
          </div>
        </template>

        <template #cell(projectStartDate)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ formatDate(data.item.projectStartDate)  }}</span>
          </div>
        </template>

        <template #cell(completionDate)="data">
          <div class="text-nowrap">
            <span v-if="data.item.completionDate" class="align-text-top">{{ data.item.completionDate ? formatDate(data.item.completionDate) : '' }}</span>
            <span v-else class="align-text-top">-</span>
          </div>
        </template>

        <template #cell(status)="data">
          <div class="text-nowrap">
            <span :class="[getStatusClass(data.item.status)]" class="rounded align-text-top p-5px">{{ data.item.status }}</span>
          </div>
        </template>

        <template #cell(projectTypeDesc)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.projectTypeDesc }}</span>
          </div>
        </template>
      </b-table>

      <div class="mx-2 mb-2">
        <b-row>
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-start">
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries
            </span>
          </b-col>
          <!-- Pagination -->
          <b-col cols="12" sm="6" class="d-flex align-items-center justify-content-center justify-content-sm-end">
            <b-pagination v-model="currentPage" :total-rows="totalProjects" :per-page="perPage" first-number last-number
              class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>

              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BPagination, BLink, BAvatar, BBadge, BDropdown, BDropdownItem, BInputGroup, fontAwesomeIcon,
  BDropdownForm, BFormSelect, BFormDatepicker, BFormGroup

} from 'bootstrap-vue';
import vSelect from 'vue-select';
import ToastificationContent from '@core/components/toastification/ToastificationContent';
// import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BLink,
    BAvatar,
    BBadge,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
    BInputGroup,
    fontAwesomeIcon,
    BDropdownForm,
    BFormSelect,
    BFormDatepicker,
    BFormGroup
  },
  // directives: {
  //   Ripple,
  // },
  // mixins: [heightTransition],
  props: {
    status: String
  },
  data() {
    return {
      // isLoading: false,
      currentPage: 1,
      perPage: 100,
      perPageOptions: [10, 25, 50, 100],
      tableColumns: [
        // { key: 'sequence', label: 'Seq', sortable: true },
        { key: 'projectId', label: 'Project ID', sortable: true },
        { key: 'projectName', label: 'Project Name' },
        { key: 'ownerFullName', label: 'Client', sortable: true },
        { key: 'professionalFUllName', label: 'Designer', sortable: true },
        { key: 'country', label: 'Country' },
        { key: 'projectPercentage', label: 'Project %', sortable: true },
        { key: 'amountInUSD', label: 'Amount', sortable: true },
        { key: 'bainkomPercentage', label: 'Bainkom %', sortable: true },
        { key: 'fullAmountInUSD', label: 'Final Amount', sortable: true },
        { key: 'paymentOption', label: 'Payment Option', sortable: true },
        { key: 'projectStartDate', label: 'Start Date', sortable: true },
        { key: 'completionDate', label: 'End Date', sortable: true },
        { key: 'status', label: 'Status' },
        { key: 'projectTypeDesc', label: 'Project Type Description', sortable: true },
      ],
      sortBy: 'projectStartDate',
      sortDesc: true,

      projects: [],

      professionals: [],
      professionalsOptions: [],
      clients: [],
      clientsOptions: [],
      paymentOptions: [
        {text: 'Payment Option', value: null, disabled: true },
        {text: 'By Project', value: false, disabled: false },
        {text: 'By Milestone', value: true, disabled: false },
      ],

      statusProjects: [],
      totalProjects: 0,

      clientQuery: null,
      designerQuery: null,
      paymentQuery: null,
      creationDateQuery: null,
      completionDateQuery: null

    }
  },

  computed: {
    dataMeta() {
      return {
        from: this.perPage * (this.currentPage - 1) + (this.projects.length ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + this.projects.length,
        of: this.totalProjects,
      }
    },

    queryParams() {
      return {
        sortBy: this.sortBy,
        sortOrder: this.sortDesc ? 1 : 0,
        skip: (this.currentPage - 1) * this.perPage,
        take: this.perPage,
      }
    },
  },

  watch: {
    queryParams: {
      handler() {
        this.fetchProjects();
      },
      immediate: true
    },
    clientQuery: {
      handler() {
        // console.log(this.clientQuery);
      }
    },
    designerQuery: {
      handler() {
        // console.log(this.designerQuery);
      }
    },
    creationDateQuery: {
      handler() {
        // console.log(this.creationDateQuery);
      }
    }
  },

  created() {
    this.$http.get('/users?Type=2&skip=0&take=1000')
      .then(response => {
        // console.log('PROFESSIONALS: ', response.data);
        this.professionals = response.data.results

        const options = response.data.results.map(prof => {
          return {
            text: prof.fullName,
            value: prof.fullName
          }
        })

        options.unshift({ text: 'Professional', value: null, disabled: true })
        this.professionalsOptions = options
      })
    this.$http.get('/users?Type=3&skip=0&take=1000')
      .then(response => {
        // console.log('CLIENTS: ', response.data);
        this.clients = response.data.results

        const options = response.data.results.map(client => {
          return {
            text: client.fullName,
            value: client.fullName
          }
        })

        options.unshift({ text: 'Client', value: null, disabled: true })
        this.clientsOptions = options
      })
  },

  methods: {
    async fetchProjects() {
      const params = this.queryParams;

      if (this.status !== 'All') {
        params.status = this.status
      }

      if (this.clientQuery) {

        params.ClientName = this.clientQuery
      
      }
      if (this.designerQuery) {
      
        params.DesignerName = this.designerQuery
      
      }
      if (this.creationDateQuery) {
        
        params.CreationDate = this.creationDateQuery
      
      }
      if (this.completionDateQuery) {
        
        params.CompletionDate = this.completionDateQuery
      
      }

      if (this.paymentQuery !== null) {
        params.IsMilestone = this.paymentQuery
      }

      const response = await this.$http.get('projects', {
        params
      });

      let projects = response.data.results

      this.statusProjects = projects
      this.totalProjects = response.data.count;
    },
    getStatusClass(statusValue) {
      const classes = {
        Pending: 'draft',
        SearchingForDesigners: 'searching-for-designers',
        PriceSubmitted: 'price-submitted',
        Completed: 'completed',
        default: 'draft'
      }

      const status = statusValue;

      return classes[status] || classes.default;
    },
    formatDate(dateString) {
      const date = new Date(dateString);

      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, '0');
      const minutes = String(date.getMinutes()).padStart(2, '0');
      const seconds = String(date.getSeconds()).padStart(2, '0');

      return `${day}/${month}/${year}`;
    },

    paymentOptionClass(paymentOption) {
      return paymentOption.split(' ').join('-');
    }

  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.search-group-input {
  padding-left: 50px;
}

.search-group-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 15px;
  width: 24px;
  height: 24px;
}

.dropdown-form-container {
  width: 450px;
}

.color-inherit {
  color: inherit;
}

.width-49perc {
  width: 49%;
}

.date-filters-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.draft {
background-color: grey;
color: white;
}

.searching-for-designers {
background-color: #FFE4D9;
color: #707070;
}

.price-submitted {
background-color: #FFEFC6;
color: black !important;
}

.completed {
background-color: #D7F9E0;
color: #707070;
}

.not-available {
background-color: #f77c7c;
color: #FFE4D9;
}

.by-project {
  background-color: #2E8AB11A;
  color: #2E8AB1;
  padding: 4px 12px;
}

.by-milestone {
  background-color: #EAFAF1;
  color: #21AC5F;
  padding: 4px 12px;
}

.p-5px {
  padding: 5px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>