<template>
  <b-tabs>

    <template v-for="status in statuses" >
      <b-tab 
        lazy
        :key="status.value"
      >
        <template #title>
          <span>{{ status.text }}</span>
        </template>

        <projects-table ref="table" :status="status.value" />
      </b-tab>
    </template>

    <!-- New Tab Button (Using tabs-end slot) -->
    <template #tabs-end>
      <div class="ml-auto">

      </div>
    </template>
  </b-tabs>
</template>

<script>
import {
  BTabs, BTab, BButton, BCol, BRow,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import ProjectsTable from './ProjectsTable.vue'

export default {
  components: {
    BTabs,
    BTab,
    BButton,
    BCol,
    BRow,
    ProjectsTable
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      statuses: []
    }
  },
  created() {
    this.$http.get('/Lookups/ProjectStatus')
      .then(response => {
        console.log('LOOKUP RESPONSE: ', response);
        const data = response.data;
        this.statuses = data.sort((a, b) => a.text.localeCompare(b.text));
      })
  },
  methods: {

  },
}
</script>
